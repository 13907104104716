import "./App.css";


import img1 from "../src/assets/1.jpeg";
import img2 from "../src/assets/2.jpeg";
import img3 from "../src/assets/3.jpeg";
import img4 from "../src/assets/4.jpeg";
import img5 from "../src/assets/5.jpeg";
import img6 from "../src/assets/6.jpeg";
import img7 from "../src/assets/7.jpeg";
import img8 from "../src/assets/8.jpeg";
import img9 from "../src/assets/9.jpeg";
import img10 from "../src/assets/10.jpeg";
import img11 from "../src/assets/11.jpeg";
import img12 from "../src/assets/12.jpeg";
import img13 from "../src/assets/13.jpeg";
import img14 from "../src/assets/14.jpeg";
import img15 from "../src/assets/15.jpeg";
import img16 from "../src/assets/16.jpeg";
import img17 from "../src/assets/17.jpeg";
import img18 from "../src/assets/18.jpeg";
import img19 from "../src/assets/19.jpeg";
import img20 from "../src/assets/20.jpeg";
import img21 from "../src/assets/21.jpeg";
import img22 from "../src/assets/22.jpeg";
import img23 from "../src/assets/23.jpeg";
import img24 from "../src/assets/24.jpeg";
import img25 from "../src/assets/25.jpeg";
import img26 from "../src/assets/26.jpeg";
import img27 from "../src/assets/27.jpeg";
import img28 from "../src/assets/28.jpeg";
import img29 from "../src/assets/29.jpeg";
import img30 from "../src/assets/30.jpeg";
import img31 from "../src/assets/31.jpeg";


import Sound from 'react-sound';
import Humdard from '../src/assets/Humdard.mp3'
import Player from "./Player";




function App() {
  return (

    <div className="App">



      <header className="header">Happy 19th Birthday Trisha!!</header>
      <div className="text">
        <p>Heyyyyyy babbyyy, well Happy Birthday again!!💗</p>
        <p>
          Sooo I know that at times you feel ki I don't give you enough bhaav
          coz well I'm busy giving my attention to my coding and my WebDev...
          well that gave me the idea to do this! (Coding this from scratch btw)
        </p>
        <p>
          I thought I'll use WebDev itself to try and make you feel thoda sa
          special...
          <br />
          So this is another attempt of mine to try and make you feel special on
          your birthday my love💕💕💕💕
        </p>
        <p>
          So here's a little trip down memory lane to make your birthday even
          better babyyy 😘😘
          <br />
          Ps. Hover on the images to enjoy a bit more...😉😘💗
        </p>
      </div>
      <div className="memory">
        A TRIP DOWN MEMORY LANE...
        <Player url={Humdard}/>
      </div>
      
      <div className="main">



      {/* 1*/}
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img1} alt="img1" className="image" id="im1" />
              <div className="date">30th March '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
                The story that started it all babe...
                <br />
                You have no idea how glad I am now that you posted this story😘
              </p>
            </div>
          </div>
        </div>
      {/* 2*/}
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img2} alt="img2" className="image" id="im2" />
              <div className="date">30th March '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text" id="text2">
                Me sliding into your DMs😉😘
                <br />
                Who knew that Happy Holi will soon turn to.. I love youu❤️
                <br />
                <br />
                Btw.. yeahh I scrolled all the way back to take this ss😂❤️
              </p>
            </div>
          </div>
        </div>

      {/* 3*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img3} alt="img3" className="image" id="im3" />
              <div className="date">30th March '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
                Moving from ig to SC in the same day baby...
                <br />
                Jitna bhi bol lu you are not gonna stop saving the messages
                na😂❤️😂
              </p>
            </div>
          </div>
        </div>

      {/* 4*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img4} alt="img4" className="image" id="im4" />
              <div className="date">24th April '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
                Moving on to whatsapp a couple of weeks later just coz of some
                stupud English Assignment 😂😘❤️
              </p>
            </div>
          </div>
        </div>

      {/* 5*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img5} alt="img5" className="image" id="im5" />
              <div className="date">26th April '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
                One thing that will never change is me going for long drives and
                making you jealous😂 <br />
                Soon you'll be in the passenger's seat babe😘
              </p>{" "}
            </div>
          </div>
        </div>

      {/* 6*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img6} alt="img6" className="image" id="im6" />
              <div className="date">4th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
                Me falling in love with Tiger before Falling in love with you
                😍❤️
              </p>
            </div>
          </div>
        </div>

      {/* 7*/}

        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img7} alt="img7" className="image" id="im7" />
              <div className="date">4th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              From Trisha Jaipuriar(MIT) -> Trisha 🌈<br/>
              You became the only special contact in my phone💕💕💕
              </p>
            </div>
          </div>
        </div>

      {/* 8*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img8} alt="img8" className="image" id="im8" />
              <div className="date">12th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              From vibing on songs together, to coincidently be listening to the same song at the same time (THRICE) babyyyyy.
              😍😍😍😍
              </p>
            </div>
          </div>
        </div>

      {/* 9*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img9} alt="img9" className="image" id="im9" />
              <div className="date">21st May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Nothing much here, just you falling in love with cute baby Suryaansh. 😘❤️😘❤️😘❤️
              </p>
            </div>
          </div>
        </div>

      {/* 10*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img10} alt="img10" className="image" id="im10" />
              <div className="date">24th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              You naming Snowy and Pogo and then soon just enoying lloking at them in our video calls 😍💗😍
              </p>
            </div>
          </div>
        </div>

      {/* 11*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img11} alt="img11" className="image" id="im11" />
              <div className="date">24th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              You being scared af before the vaccine, calling me immediately afterwards, and me taking care of you while 
              you sick during the next couple of days❤️❤️. 
              </p>
            </div>
          </div>
        </div>

      {/* 12*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img12} alt="img12" className="image" id="im12"  />
              <div className="date">28th May '21</div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              The first time you sang for me❤️<br/><br/>
              Jab koi baat bigad jaaye 🎶❤️.<br/><br/>
               Its our song now babe❤️❤️❤️
              </p>
            </div>
          </div>
        </div>

      {/* 13*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img13} alt="img13" className="image" id="im13" />
              <div className="date">5th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Our First Video Call Ever babe❤️❤️❤️.<br/><br/>
              First Out of infinite ammazzinnggg video calls😍💕😍💕
              </p>
            </div>
          </div>
        </div>


      {/* 14*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img14} alt="img14" className="image" id="im14" />
              <div className="date">7th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              This is back when we were confused about how we felt each other ko leke, and sab ke opinions le 
              rahe the 😅😅💕💕💕💕
              </p>
            </div>
          </div>
        </div>


      {/* 15*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img15} alt="img15" className="image" id="im15" />
              <div className="date">10th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Our late night meets are bessssttttt 😍💗😍💗😍💗
              </p>
            </div>
          </div>
        </div>


      {/*16 */}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img16} alt="img16" className="image" id="im16" />
              <div className="date">
              10th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Me falling asleep while talking to you late night will always be a constant.
              😘😘😘😘😘😘💗💗💗
              </p>
            </div>
          </div>
        </div>


      {/*17 */}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img17} alt="img17" className="image" id="im17" />
              <div className="date">
              17th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Me finaalllyyy getting my hair cut babyy 😂😘😂😘
              </p>
            </div>
          </div>
        </div>


      {/*18 */}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img18} alt="img18" className="image" id="im18" />
              <div className="date">
              21st June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Finaallyyy clicking a good picture together 😘😘 <br/><br/>
              Cant wait to take pictures physically together babe 🥺🥺💓💓💓
              </p>
            </div>
          </div>
        </div>


      {/* 19*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img19} alt="img19" className="image" id="im19" />
              <div className="date">
              21st June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Felt realllyyy good to create a photo album and google drive folder, specially for us my loveee ❤️❤️❤️❤️❤️
              </p>
            </div>
          </div>
        </div>

      {/* 20*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img20} alt="img20" className="image" id="im20" />
              <div className="date">
              24th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Well, this morning was one of the best mornings of my life, coz a new chapter had started,
               one with you, one that will go on for the rest of my life. 😘💕😘💕
              </p>
            </div>
          </div>
        </div>



      {/* 21*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img21} alt="img21" className="image" id="im21" />
              <div className="date">
              25th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Using EG as an excuse to spend time with each other 💓💓<br/><br/>
              Who says doing EG can't be fun?!? 😉😘😘
              </p>
            </div>
          </div>
        </div>



      {/* 22*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img22} alt="img22" className="image" id="im22" />
              <div className="date">
              26th June '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Can't wait to replace this pillow with you and hug you soooo soooooooo tight!! <br/>
              💓🫂💓🫂💓
              </p>
            </div>
          </div>
        </div>



      {/* 23*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img23} alt="img23" className="image" id="im23" />
              <div className="date">
              5th July '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Hehehehe.... even when you're annoyed with me... you look the cutteessssttt 😍🥺😍🥺😍
              </p>
            </div>
          </div>
        </div>




      {/* 24*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img24} alt="img24" className="image" id="im24" />
              <div className="date">
              12th July '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
            Nothing much here... just one of your fav pics of me😉😘<br/>
            I know you love this tshirt😘❤️...Enjoyyyyyy😘😘😘😘
              </p>
            </div>
          </div>
        </div>



      {/* 25*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img25} alt="img25" className="image" id="im25" />
              <div className="date">
              19th July '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              You choosing clothes for me while shopping, for the first time babee 💗💗💗
              </p>
            </div>
          </div>
        </div>


      {/* 26*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img26} alt="img26" className="image" id="im26" />
              <div className="date">28th July '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Thank you my love, for making this the moossstt special birthday ever!! <br/>
              💕💕💕💕💕💕
              </p>
            </div>
          </div>
        </div>


      {/* 27*/}
      {/* <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img27} alt="img27" className="image" id="im27" />
              <div className="date">
              30th July '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              Just putting this here babe, so that you got some hotness to enjoyy<br/><br/>
               😉😉😘😘😘💗💗💗💗
              </p>
            </div>
          </div>
        </div>
 */}

      {/* 28*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img28} alt="img28" className="image" id="im28" />
              <div className="date">1st August '21 
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              This is just me starting a birthday count down for you, in an attempt to make your
              entire birthday month and not just your birthday special 💕💕💕💕
              </p>
            </div>
          </div>
        </div>


      {/* 29*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img29} alt="img29" className="image" id="im29" />
              <div className="date">
              3rd August '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              This is just an appreciation post for tiger, coz he is the cutteessssttt 😍😍😍😍😍😍
              </p>
            </div>
          </div>
        </div>


      {/* 30*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img30} alt="img30" className="image" id="im30" />
              <div className="date">
              20th August '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              The short, randomm video calls while you are shopping, to get my opinion on your clothes are the 
              bessssttttt 💖💖💖💖
              </p>
            </div>
          </div>
        </div>


      {/* 31*/}
      <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <img src={img31} alt="img31" className="image" id="im31" />
              <div className="date">
              23rd August '21
              </div>
            </div>
            <div className="flip-card-back">
              <p className="back-text">
              You are the prettiieeesssttttt babyyyyyyy!! <br/><br/>

              😍💘😍💘😍💘😍
              </p>
            </div>
          </div>
        </div>





      </div>
    <div className="bottom">
I Love You! ❤️
    </div>
    
    </div>
  )
}



export default App;
